:root{
    --theme: rgb(254,166,33);
    --old_theme: #ebaf2e;
    --theme_hover: #eb9f2e;
    --transparent_theme: #ebaf2e93;
    --light_theme:#f3ead8;
}
.logo{
    height: 80px;
}
.link{
    transition: color .2s;
    color: var(--theme);
    text-decoration: none;
}
.link.active{
    transition: color .4s;
    padding: 5px;
    border-radius:5px;
    background-color: var(--theme);
    color: white;
}
.link:hover{
    color: var(--theme_hover);
}
.link.active:hover{
    color: black;
}
.nav{
    transition: width .5s;
    display: flex;
    background-color: white;
    user-select: none;
    height: 75px;
}
.sticky{
    position: fixed!important;
    top: 10px;
    left: 50%;
    width: 50%;
    z-index: 100;
    transform: translateX(-50%);
    animation: .5s slide_nav forwards;
}
.sticky img{
    border-radius: 100px 0px 0px 100px;
    height: 75px;
}
@keyframes slide_nav {
    to{
        border-radius: 100px;
        
        background-color: rgba(255, 255, 255, 0.85);
        top: 20px;
    }
}
footer{
    padding: 16px;
}
.c_pointer *{
    cursor: pointer;
}
.scroll_to_top{
    position: fixed;
    height: 50px;
    width: 50px;
    border-radius: 20px;
    bottom: -80px;
    right: 30px;
    background-color: var(--theme);
    color: white;
    box-shadow:inset 0px 0px 0px 25px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: all .5s;
    z-index: 1000;
    cursor: pointer;
}
.scroll_to_top i{
    transition: transform .2s;
    transform: translate(14px,12px);
}
.scroll_to_top:hover{
    background-color: var(--theme_hover);
    box-shadow:inset 0px 0px 0px 25px rgba(0, 0, 0, 0.3);
}
.scroll_to_top:hover i{
    transform: translate(14px,10px);
}
.scroll_to_top.scroll{
    bottom: 80px;
    opacity: 1;
}
.menu{
    display: none;
    cursor: pointer;
    user-select: none;
}
.menu span{
    height: 15px;
    width: 2px;
    background-color: var(--theme);
    transition: all .3s;
}
.links{
    height: fit-content;
    width: 100%;
    transition: all .3s;
}
i.fa{
    user-select: none;
}
@media screen and (max-width: 770px){
    .sticky{
        width: 100%;
    }
    .nav{
        position: relative;
        display: grid;
        grid-template-columns: repeat(2,auto);
    }
    .menu{
        display: flex;
        gap: 5px;
    }
    .links{
        transition: all .2s;
        overflow: hidden;
        position: absolute;
        flex-direction: column;
        height: 0px;
        width: 80%;
        left: 10px;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        z-index: 1000;
    }
    .nav:has(#menu:checked) .links{
        height: 140px;
        background-color: rgba(255,255,255,0.85);
    }
    .nav:has(#menu:checked) .menu span:first-child{
        transform: translateX(3px);
        rotate: -45deg;
    }
    .nav:has(#menu:checked) .menu span:nth-child(2){
        opacity: 0;
    }
    .nav:has(#menu:checked) .menu span:last-child{
        transform: translateX(-3px);
        rotate: 45deg;
    }
}