p{
    padding: 0px;
    margin: 0px;
}
.first_section{
    position: sticky;
    top: 0;
    height: 100vh;
    user-select: none;
    opacity: 0;
}
.first_section.visible{
    opacity: 1;
}
.über_uns{
    position: relative;
    margin-top: 200px;
    height: 100dvh;
    background:url('../images/Backgrounds/background_1.jpg');
    background-size:cover;
    background-repeat: no-repeat;
    color: white;
}
.über_uns .text{
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 20px;
    background-color: #102a3d6e;
    transform: translate(-50%,-50%);
}
.slide_up{
    animation: var(--animation_speed) slide_up forwards linear;
}
.m_icon{
    width: 75px;
    height: 75px;
    transition: all .3s;
    text-shadow: 0px 0px 0px 1px white;
    box-shadow: inset 0px 0px 0px 100px var(--theme),
    0px 0px 10px 5px white,
    0px 0px 0px 10px var(--theme);
    border-radius: 50px;
    padding: 17px 12px 17px 12px;
}
.m_icon:hover{
    text-shadow: 0px 0px 0px 1px white;
    box-shadow: inset 0px 0px 0px 100px #ffffff,
    0px 0px 10px 5px white,
    0px 0px 0px 10px var(--theme);
    color: var(--theme);
    border-radius: 20px;
    padding: 17px 12px 17px 12px;
}
.über_uns,.mitarbeiter,.elektroanlagen,.cards div{
    opacity: 0;
}
.third_section{
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, var(--light_theme) 7%, var(--light_theme) 96%, var(--transparent_theme) 100%);
}
.anlagen,.anlage{
    width: 50%;
}
.anlagen{
    transition: all .2s;
    border-radius: 20px 0px 0px 20px ;
    background-color: var(--theme);
}
.anlagen h5{
    cursor: pointer;
    border-radius: 20px 0px 0px 20px ;
    padding: 20px;
}
.anlagen h5:last-child{
    margin-bottom: 0px;
}
.anlagen h5.active{
    cursor:auto;
    background-color: white;
}
.anlagen,.anlage {
    user-select: none;
}
.anlage{
    position: relative;
}
.anlage div{
    position: absolute;
    width: 80%;
    top: 0%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: opacity .2s,top .2s,transform .2s;
    opacity: 0;
}
.anlage div.active{
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%,-50%);
}
.anlage{
    border-radius: 0px 20px 20px 0px ;
    background-color: white;
}
.anlage div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.anlage h6{
    width: fit-content;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    background: linear-gradient(90deg, transparent 0%, var(--theme) 10%, var(--theme) 90%, transparent 100%);
}
.cards{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:20px;
}
.cards div{
    padding: 20px;
}
.cards .karriere{
    grid-column: 1/3;
}
.cards .karriere p{
    border-radius: 25px;
    box-shadow: inset 0px 0px 100px 5px rgba(255, 255, 255, 1);
    font-size:larger;
}
.cards .karriere p a{
    transition: color .2s;
    text-decoration: none;
    color: var(--theme);
}
.cards .karriere p a:hover{
    color: var(--theme_hover);
}
.bg_theme{
    background-color: var(--theme);
}

@keyframes slide_up {
    from{
        transform: translateY(10%);
    }
    to{
        transform: translateY(0%);
        opacity: 1;
    }
}
@media screen and (max-width: 1000px) {
    .cards .karriere p{
        font-size:large;
    }
} 
@media screen and (max-width: 770px) {
    .über_uns h3{
        font-size: 22px;
    }
    .cards{
        grid-template-columns: 1fr;
    }
    .cards .karriere{
        grid-column: 1/2;
    }
} 
@media screen and (max-width: 500px) {
    .elektroanlagen h5,.elektroanlagen h6{
        font-size: 13px;
    }
    .anlagen{
        width: 41.5%;
    }
    .anlage{
        width: 58.5%;
    } 
    .über_uns h3{
        font-size: 20px;
    }
    .cards .karriere p{
        font-size:medium;
    }
}
@media screen and (max-width: 400px) {
    .elektroanlagen h5,.elektroanlagen h6{
        font-size: 12px;
    }
} 
@media screen and (max-width: 380px) {
    .anlagen{
        width: 40.5%;
    }
    .anlage{
        width: 59.5%;
    }
} 
@media screen and (max-width: 370px) {
    .anlagen,.anlage{
        word-break: break-all;
    }
} 