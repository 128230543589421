:root{
    --slider_speed: 9s;
    --animation_speed:.5s;
}
.logos{
    border-top: 2px solid var(--theme);
    border-bottom: 2px solid var(--theme);
    overflow: hidden;
    white-space: nowrap;
    padding: 60px 0;
    background-color: white;
}
.logos_slide{
    max-height: 20vh;
    display: inline-block;
    animation: var(--slider_speed) slide infinite linear;
}
.r_logo{
    margin: 0 40px;
    height: 55px;
}
.logos:hover .logos_slide{
    animation-play-state: paused;
}
.r_logo.bremmer{
    height: 35px;
}
.r_logo.schulte{
    height: 65px;
}
.r_logo.aldi{
    height: 85px;
}
.r_logo.wachtel{
    padding: 20px;
    height: 80px;
    border-radius: 20px;
    background: radial-gradient(circle, #102a3d 0%, #102a3d 28%, rgba(159,159,159,0) 100%);
}
@keyframes slide {
    from{transform: translateX(0);}
    to{transform: translateX(-100%);}
}
.reference_section .r_image{
    position: absolute;
    width: 100%;
    height: 90dvh;
    object-fit: cover;
    transition: opacity .2s;
    border-radius: 20px;
}
.reference_section{
    position: relative;
    height: 90dvh;
    width: 100%;
}
.reference_section .reference_text{
    border-radius: 20px;
    padding: 24px;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: rgb(255,255,255,.5);
}
.reference_section .r_image:not(.active){
    opacity: 0;
}
.reference_section.no_image{
    position: relative;
    height: auto;
    color: black;
}
.reference_section.no_image .reference_text{
    position:static;
    transform: translate(0%,0%);
    background-color: transparent;
}
.reference_section .image_progress:not(.start){
    position: absolute; 
    height: 2px;
    bottom: 10px;
    width: 0%;
    background-color: white;
}
.reference_section .image_progress.start{
    position: absolute; 
    height: 2px;
    bottom: 10px;
    background-color: white;
    animation: progress_bar 5s infinite linear;
}
.standort{
    display: grid;
    grid-template-columns: repeat(4,auto);
    gap: 10px;
}
.standort span{
    padding: 5px;
    border-radius: 10px;
    background-color: var(--transparent_theme);
    user-select: none;
}
.other_images {
    position: absolute;
    bottom: 120px;
    left: 52.5%;
    transform: translateX(-50%);
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4,auto);
    z-index: 2;
}
.other_images *{
    max-width: fit-content;
}
.other_images .image_progress{
    display: none;
}
.other_images .r_image{
    transition: all .5s;
    min-width: 200px;
    max-width: 200px;
    height: 120px;
    object-fit: cover;
    filter: blur(1px);
    outline: 2px solid rgba(0,0,0,.5);
    border-radius: 20px;
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.5);
    cursor: pointer;
}
.other_images .r_image:hover{
    filter: blur(0px);
    outline: 2px solid rgba(0,0,0,.75);
    box-shadow: 0px 0px 20px 4px rgb(0,0,0);
}
.other_images .r_image:not(.active){
    display: inline-block;
}
.other_images .r_image.active{
    opacity: 0;
    width: 0px;
    min-width: 0px;
    max-width: 0px;
    animation: p_absolute 0s .5s forwards;
}
@keyframes p_absolute {
    to{position: absolute;}
}
@keyframes progress_bar {
    0%,20%{width: 0%;}
    100%{width: 100%;}
}
.no_image_references {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
}
.no_image_references .referenz {
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border: 1px solid rgba(0,0,0,.5);
    border-radius: 25px;
}
.no_image_references .referenz img.r_logo.bremer{
    height: 35px;
}
@media screen and (max-width: 1000px) {
    .no_image_references .referenz {
        width: 96%;
        margin-left: 15px;
        margin-right: 15px;
    }
    .no_image_references {
        bottom: 100px;
        grid-template-columns: 1fr;
    }
} 
@media screen and (max-width: 770px) {
    .no_image_references .referenz {
        width: 97.5%;
        margin-left: 0px;
        margin-right: 0px;
    }
    .reference_section{
        position: relative;
        height: 90dvh;
        width: 97%;
    }
    .no_image_references .referenz img.r_logo.bremer{
        height: 25px;
    }
    .other_images .r_image{
        filter: blur(0px);
        min-width: 130px;
        max-width: 130px;
    }
    .r_logo{
        height: 45px;
    }
    .r_logo.wachtel{
        height: 60px;
    }
    .r_logo.aldi{
        height: 55px;
    }
    .r_image.r_logo.bremmer{
        height: 30px;
    }

} 
@media screen and (max-width: 550px) {
    .other_images .r_image{
        min-width: 85px;
        max-width: 85px;
    }
} 
@media screen and (max-width: 450px) {
    body{
        overflow-x: hidden;
    }
    .no_image_references .referenz img.r_logo.bremer{
        height: 20px;
    }
    .no_image_references .referenz img.r_logo{
        height: 40px;
    }
    .r_logo.wachtel{
        height: 60px!important;
    }
    .r_logo{
        height: 30px;
    }
} 
@media screen and (max-width: 350px) {
    .other_images .r_image{
        min-width: 40px;
        max-width: 40px;
    }
} 
@media screen and (max-height:800px) {
    .other_images{
        bottom: 80px;
    }
}
@media screen and (max-height:730px) {
    .other_images{
        bottom: 50px;
    }
}
@media screen and (max-height:600px) {
    .other_images .r_image{
        height: 90px;
    }
}