*{margin: 0px;padding: 0px;}
:root{
    --button-background:transparent;
}
.carousel_text{
    padding-top: 20px;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 25%;
    box-shadow: inset 0px 20px 40px 2px rgba(0,0,0,.5);
    top: 75%;
    color: white;
    z-index: 1;
    user-select: none;
}
.carousel_model{
    position: relative;
    transition: rotate .2s;
    transition-delay: 1.2s;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 200px;
}
.carousel_model .image{
    position: relative;
    transition: transform .2s;
    transition-delay: 2s;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    background: transparent;
}
.carousel_model .image img{
    position: absolute;
    transition: all .2s;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 1px;
}
.carousel_model .image img:not(.active){
    opacity: 0;
}
.carousel_btn{
    position: absolute;
    transition: all .2s;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    border-radius: 15px;
    border:0px;
    align-self: center;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.5);
    outline: 1px solid transparent;
    color: white;
    cursor: pointer;
    z-index: 2;
}
.btn_left{
    left: 20px;
}
.btn_right{
    right: 20px;
}
.carousel_btn.active{
    outline: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
}
.carousel_btn:hover{
    outline: 1px solid white;
    border-radius: 15px;
    color: white;
}
.carousel_model .quick_switch{
    position: absolute;
    display: flex;
    gap: 20px;
    bottom: 20px;
    z-index: 2;
}
.carousel_model .quick_switch button{
    position: relative;
    transition: height .2s,margin-bottom .2s;
    width: 20px;
    height: 5px;
    border: 0px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.507);
}
.carousel_model .quick_switch button.active{
    background-color: white;
}
.carousel_model .quick_switch button:not(.active):hover{
    margin-bottom: -10px;
    height: 10px;
}
.carousel_model .quick_switch button::after{
    content: '';
    position: absolute;
    top: 0px;
    outline: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    left: 0px;
    width: 20px;
    height: 0px;
    background: var(--button-background);
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity .2s, height .5s ,top .5s,left .5s, width .5s;
}
.carousel_model .quick_switch button:not(.active):hover::after{
    top: -10px;
    left: -5px;
    height: 20px;
    width: 30px;
    opacity:.75;
}
.carousel_model .progress_bar{
    position: absolute; 
    height: 2px;
    bottom: 10px;
    background-color: white;
    animation: progress_bar 5s infinite linear;
}
@keyframes progress_bar {
    0%,20%{width: 0%;}
    100%{width: 100%;}
}