.kontakt .info{
    width: fit-content;
}
.kontakt .info a{
    transition: color .2s;
    text-decoration: none;
    color: black;
}
.kontakt .info a:hover{
    color: var(--theme);
}